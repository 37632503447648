import React, { useEffect } from "react";
import styles from "./style.module.scss";
import { CloseIcon } from "../svg-components";
import { useDispatch } from "react-redux";
import { clearMessage } from "../../redux/features/toast/toastSlice";

export const ToastMessage = ({ message, type }) => {
  const dispatch = useDispatch();
  const handleToast = () => {
    dispatch(clearMessage());
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(clearMessage());
    }, 3000);
    return () => {
      clearTimeout(timeout);
    };
  }, [dispatch]);
  return (
    <section className={styles.notificationShow}>
      <div className={styles.notification}>
        <div className={styles.notificationHeader}>
          <div className={styles.title}>
            <span className={styles.image}>{type?.icon}</span>
            <p className={styles.message}>{message}</p>
          </div>
          <CloseIcon
            fillColor={type.crossColor}
            handleClick={() => handleToast()}
            customClass={styles.closeIcon}
          />
        </div>
      </div>
    </section>
  );
};
