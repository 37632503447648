/* eslint-disable import/namespace */
import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  userDetails: {},
  notificationList: [],
};

const registrationSlice = createSlice({
  name: "registration",
  initialState,
  reducers: {
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    setNotificationData: (state, action) => {
      state.notificationList = action.payload;
    },
  },
});
export const { setUserDetails, setNotificationData } =
  registrationSlice.actions;
export default registrationSlice.reducer;
