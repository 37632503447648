// system roles list
const systemRoles = {
  ADMIN: "Admin",
  SOFTWARE: "Software",
  MANAGER: "Manager",
  STAFF: "Staffing",
  SALES: "Sales",
  ACCOUNTS: "Account",
  HR: "HR",
  SYSTEM_ADMIN: "System Admin",
};

const sysytemModule = {
  HR_MDL: "HR",
};

export const {
  ADMIN,
  SOFTWARE,
  MANAGER,
  STAFF,
  SALES,
  ACCOUNTS,
  HR,
  SYSTEM_ADMIN,
} = systemRoles;
export const empRoles = [
  SOFTWARE,
  MANAGER,
  STAFF,
  SALES,
  ACCOUNTS,
  HR,
  SYSTEM_ADMIN,
];

export const { HR_MDL } = sysytemModule;
