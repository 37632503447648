/* eslint-disable import/namespace */
import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  addemp: {},
  getProfile: {},
  employeeID: "",
  employeeList: [],
  conformationEmployeeList: [],
};

const employeeSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {
    addEmployee: (state, action) => {
      state.addemp = action.payload;
    },
    editEmployeebyId: (state, action) => {
      // state.employeeId = action.payload;
      state.employeeID = action.payload;
    },
    setProfile: (state, action) => {
      state.getProfile = action.payload;
    },
    // setEmployeeId: (state, action) => {
    //   state.employeeID = action.payload;
    // },

    setEmployeeList: (state, action) => {
      state.employeeList = action.payload;
    },
    setConformationEmployeeList: (state, action) => {
      state.conformationEmployeeList = action.payload;
    },
  },
});
export const {
  addEmployee,
  editEmployeebyId,
  setProfile,
  setEmployeeId,
  setEmployeeList,
  setConformationEmployeeList
} = employeeSlice.actions;
export default employeeSlice.reducer;
