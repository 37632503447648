/* eslint-disable import/namespace */
import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  leaveList: {},
  getLeaveStatusList: [],
  getRemainingLeaveData: {},
  editLeaveData: {},
  addLeaveData: {},
  leaveListData: [],
}

const leaveSlice = createSlice({
  name: "leave",
  initialState,
  reducers: {
    getLeaveList: (state, action) => {
      state.leaveList = action.payload;
    },
    getAllStatusPayloadData: (state, action) => {
      state.getLeaveStatusList = action.payload;
    },
    getAllRemainingLeavePayloadData: (state, action) => {
      state.getRemainingLeaveData = action.payload;
    },
    setAddLeaveData: (state, action) => {
      state.addLeaveData = action.payload;
    },
    setEditLeaveData: (state, action) => {
      state.editLeaveData = action.payload;
    },
    clearLeaveData: (state) => {
      state.editLeaveData = {};
      state.getRemainingLeaveData = {};
      state.leaveList = {};
    },
    setLeaveList: (state, action) => {
      state.leaveListData = action.payload;
    },
  },
});
export const {
  getLeaveList,
  getAllStatusPayloadData,
  setEditLeaveData,
  clearLeaveData,
  setAddLeaveData,
  getAllRemainingLeavePayloadData,
  setLeaveList,
} = leaveSlice.actions;
export default leaveSlice.reducer;
